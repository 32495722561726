import dynamic from 'next/dynamic';

const Accordion = dynamic(() =>
  import('../ContentElementsGdds/Accordion/accordion').then((module) => module.Accordion),
);

const NordicsAccordion = dynamic(() =>
  import('../ContentElementsGdds/NordicsAccordion/accordion').then((module) => module.Accordion),
);

const NordicsOnlineCatalogAssets = dynamic(() =>
  import('../ContentElementsGdds/NordicsOnlineCatalog/online-catalog-assets').then(
    (module) => module.NordicsOnlineCatalogAssets,
  ),
);

const CTAButton = dynamic(() =>
  import('../ContentElementsGdds/CTA/cta-button').then((module) => module.CTAButton),
);

const InteractiveHotspot = dynamic(() =>
  import('../ContentElementsGdds/InteractiveHotspot/interactive-hotspot').then(
    (module) => module.InteractiveHotspot,
  ),
);

const HighlightTeaser = dynamic(() =>
  import('../ContentElementsGdds/HighlightTeaser/highlight-teaser').then(
    (module) => module.HighlightTeaser,
  ),
);

const ExpressiveTiles = dynamic(() =>
  import('../ContentElementsGdds/Tiles/expressive-tiles').then((module) => module.ExpressiveTiles),
);

const ProductiveTiles = dynamic(() =>
  import('../ContentElementsGdds/Tiles/productive-tiles').then((module) => module.ProductiveTiles),
);

const HeroTiles = dynamic(() =>
  import('../ContentElementsGdds/Tiles/hero-tiles').then((module) => module.HeroTiles),
);

const TextImage = dynamic(() =>
  import('../ContentElementsGdds/TextImage/text-image').then((module) => module.TextImage),
);

const TextMediaColumn = dynamic(() =>
  import('../ContentElementsGdds/TextImage/text-media-column').then(
    (module) => module.TextMediaColumn,
  ),
);

const ProductComparison = dynamic(() =>
  import('../ContentElementsGdds/product-comparison/product-comparison').then(
    (module) => module.ProductComparison,
  ),
);

const InvestorsCalendar = dynamic(() =>
  import('../ContentElementsGdds/InvestorsCalendar/investors-calendar').then(
    (module) => module.InvestorsCalendar,
  ),
);

const IntroText = dynamic(() =>
  import('../ContentElementsGdds/IntroText/intro-text').then((module) => module.IntroText),
);

const Tabs = dynamic(() =>
  import('../ContentElementsGdds/Tabs/tabs').then((module) => module.GddsTabs),
);

const ImageGallery = dynamic(() =>
  import('../ContentElementsGdds/image-gallery/image-gallery').then(
    (module) => module.ImageGallery,
  ),
);

const Video = dynamic(() =>
  import('../ContentElementsGdds/Video/video').then((module) => module.Video),
);

const DownloadsLinkList = dynamic(() =>
  import('../ContentElementsGdds/DownloadsLinkList/downloads-link-list').then(
    (module) => module.DownloadsLinkList,
  ),
);

const Quote = dynamic(() =>
  import('../ContentElementsGdds/Quote/quote').then((module) => module.Quote),
);

const InsightsTeaser = dynamic(() =>
  import('../ContentElementsGdds/Teaser/insights-teaser').then((module) => module.InsightsTeaser),
);

const IframeWrapper = dynamic(() =>
  import('../ContentElementsGdds/Iframe/iframe-wrapper').then((module) => module.IframeWrapper),
);

const FullGridImage = dynamic(() =>
  import('../ContentElementsGdds/FullGridImage/full-grid-image').then(
    (module) => module.FullGridImage,
  ),
);

const ChartTeaser = dynamic(() =>
  import('../ContentElementsGdds/ChartTeaser/chart-teaser').then((module) => module.ChartTeaser),
);

const Contact = dynamic(() =>
  import('../ContentElementsGdds/Contact/wrapper').then((module) => module.Wrapper),
);

const ProductSlider = dynamic(() =>
  import('../ContentElementsGdds/ProductSlider/product-slider').then(
    (module) => module.ProductSlider,
  ),
);

const Jobs = dynamic(() =>
  import('../ContentElementsGdds/Jobs/jobs').then((module) => module.Jobs),
);

const MediaReleases = dynamic(() =>
  import('../ContentElementsGdds/MediaReleases/media-releases').then(
    (module) => module.MediaReleases,
  ),
);

const WorldRegions = dynamic(() =>
  import('../ContentElementsGdds/WorldMapSection/world-regions').then(
    (module) => module.WorldRegions,
  ),
);

const History = dynamic(() =>
  import('../ContentElementsGdds/History/history-container').then(
    (module) => module.HistoryContainer,
  ),
);

const TeaserTiles = dynamic(() =>
  import('../ContentElementsGdds/TeaserTilesNordics/teaser-tiles').then(
    (module) => module.TeaserTiles,
  ),
);

const SlidingTabs = dynamic(() =>
  import('../ContentElementsGdds/SlidingTabs/sliding-tabs').then((module) => module.SlidingTabs),
);

const ProductTiles = dynamic(() =>
  import('../ContentElementsGdds/Tiles/Product/product-tiles').then(
    (module) => module.ProductTiles,
  ),
);

const InteractiveDescription = dynamic(() =>
  import('../ContentElementsGdds/InteractiveDescriptions/interactive-description').then(
    (module) => module.InteractiveDescription,
  ),
);

const LeadModule = dynamic(() =>
  import('../ContentElementsGdds/lead-module/lead-module').then((module) => module.LeadModule),
);

const Locator = dynamic(() => import('./Locator/GDDSLocator').then((module) => module.Locator));

const CiamProfile = dynamic(() =>
  import('../ContentElementsGdds/ciam/ciam-profile').then((module) => module.Profile),
);

const CiamVerification = dynamic(() =>
  import('../ContentElementsGdds/ciam/ciam-verification').then((module) => module.Verification),
);

const CiamReset = dynamic(() =>
  import('../ContentElementsGdds/ciam/ciam-reset').then((module) => module.Reset),
);

const Awards = dynamic(() =>
  import('../ContentElementsGdds/awards/awards').then((module) => module.Awards),
);

const CountrySwitch = dynamic(() =>
  import('../ContentElementsGdds/country-switch/country-switch').then(
    (module) => module.CountrySwitch,
  ),
);

const Picto = dynamic(() =>
  import('../ContentElementsGdds/picto/picto').then((module) => module.Picto),
);

const Table = dynamic(() =>
  import('../ContentElementsGdds/table/table').then((module) => module.Table),
);

const Usercentrics = dynamic(() =>
  import('../ContentElementsGdds/usercentrics/usercentrics').then((module) => module.Usercentrics),
);

const SectionSlider = dynamic(() =>
  import('../ContentElementsGdds/section-slider/section-slider').then(
    (module) => module.SectionSlider,
  ),
);

const PictureSlider = dynamic(() =>
  import('../ContentElementsGdds/picture-slider/picture-slider').then(
    (module) => module.PictureSlider,
  ),
);

export const contentElementsGdds = {
  accordion: { component: Accordion },
  accordion_nordics: { component: NordicsAccordion },
  call_to_action_button: { component: CTAButton },
  expressive_tiles: { component: ExpressiveTiles },
  productive_tiles: { component: ProductiveTiles },
  hero_tiles: { component: HeroTiles },
  tiles: { component: ProductiveTiles },
  tabs_nordics: { component: SlidingTabs },
  investors_calendar: { component: InvestorsCalendar },
  interactive_hotspot: { component: InteractiveHotspot },
  highlight_teaser_section: { component: HighlightTeaser },
  intro_section: { component: IntroText },
  tabs: { component: Tabs, isContainer: true },
  image_gallery_group: { component: ImageGallery },
  nordics_image_gallery: { component: ImageGallery },
  video_section: { component: Video },
  downloads_and_link_list: { component: DownloadsLinkList },
  quote: { component: Quote },
  insights_teaser_section: { component: InsightsTeaser },
  iframe_content_section: { component: IframeWrapper },
  full_grid_image: { component: FullGridImage },
  charts: { component: ChartTeaser },
  contact: { component: Contact },
  interactive_product_slider: { component: ProductSlider },
  jobs_section: { component: Jobs },
  history: { component: History },
  text_image: { component: TextImage },
  text_media: { component: TextImage },
  text_media_columns_section: { component: TextMediaColumn },
  media_releases: { component: MediaReleases },
  world_map_section: { component: WorldRegions },
  contact_tiles: { component: ProductiveTiles },
  nordics_teaser_tiles: { component: TeaserTiles },
  nordics_product_tiles: { component: ProductTiles },
  nordics_interactive_description: { component: InteractiveDescription },
  nordics_lead_module: { component: LeadModule },
  locator: { component: Locator },
  ciam_verification: { component: CiamVerification },
  ciam_pw: { component: CiamReset },
  profile_page_embedded: { component: CiamProfile },
  awards: { component: Awards },
  country_switch: { component: CountrySwitch },
  picto: { component: Picto },
  table: { component: Table },
  usercentrics: { component: Usercentrics },
  section_slider: { component: SectionSlider },
  picture_slider: { component: PictureSlider },
  product_comparison: { component: ProductComparison },
  online_catalog_assets: { component: NordicsOnlineCatalogAssets },
};
